// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")
require('jquery')
require("session_authorize/new.js")
require("minicharts")
require("facebook_pixel")
require("google_events")
require("registrations/new.js")

import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';

library.add(faEye);
library.add(faEyeSlash);

import 'bootstrap'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons'

library.add(faCaretRight)


$(document).ready(function() {
  var offset = -(new Date().getTimezoneOffset() / 60)
  setCookie('time_zone', offset, 1)

  try {
    if (location.href.indexOf('ref=thalesbaiao1') > -1) {
      localStorage.setItem('affilliate', 'thalesbaiao1');
    }
    if (localStorage.getItem('affilliate') == 'thalesbaiao1') {
      $('.checkout-button-plan')[0].innerText = 'TESTAR GRATUITAMENTE POR 14 DIAS'
    }
  } catch (error) {}

  var btn = $('.waiting-btn');
  btn.on('click', function() {
    $(this).text('...');
    $(this).addClass('spinner spinner-left spinner-darker-success pl-15 disabled');
  });

  $('.format-date').each(function(i, date) {
    const localDate = new Date($(date).text());
    $(date).text(moment(localDate).format('DD/MM/YYYY HH:mm'));
  })
});

dom.watch();

function setCookie(name,value,days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
