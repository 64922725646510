$(document).ready(function () {
  if ($('.session-authorize').length === 0) { return; }

  const urlParams = new URLSearchParams(window.location.search);
  const userEmail = urlParams.get('email');
  const userToken = urlParams.get('token');
  const pathToRedirect = urlParams.get('path') || false

  if (userEmail && userToken) {
    $.ajax({
      type: "POST",
      beforeSend: function (request) {
        request.setRequestHeader("user-email", userEmail);
        request.setRequestHeader("user-token", userToken);
      },
      data: { path: pathToRedirect, user_email: userEmail, user_token: userToken },
      url: "/users/session_authorize",
      success: function (res) {
        window.location = res.redirect_to;
      },
      error: function () {
        window.location = '/';
      }
    });
  } else {
    window.location = '/';
  }
});
