// O seguintes eventos são emitidos diretamente por uma ação na tela:
// Contact: app/views/devise/registrations/new.html.erb
// InitiateCheckout: app/views/users/subscriptions/new.html.erb
// AddToCart: app/views/users/subscriptions/new.html.erb

// Os outros são salvos no cookie e capturados nesse arquivo.

$(document).ready(function() {
  switch (getCookie('fpx')) {
    case 'lead':
      try { fbq('track', 'Lead') } catch (error) {}
      setCookie('fpx', undefined)
      break;
    case 'subscribe':
      try { fbq('track', 'Subscribe', { value: getCookie('fpx_value'), currency: 'BRL' }) } catch (error) {}
      try { fbq('track', 'Purchase', { value: getCookie('fpx_value'), currency: 'BRL' }) } catch (error) {}
      setCookie('fpx', undefined)
      setCookie('fpx_value', undefined)
      break;
  }
});

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}