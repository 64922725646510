$(document).ready(function() {
  switch (getCookie('gpx')) {
    case 'lead':
      gtag('event', 'conversion', {
        'send_to': 'AW-10853702692/llJiCJOByNcDEKS4ubco',
      });
      setCookie('gpx', undefined)
      break;
    case 'subscribe':
      gtag('event', 'conversion', {
        'send_to': 'AW-10853702692/GI5tCO7wx9cDEKS4ubco',
        'value': getCookie('gpx_value'),
        'currency': 'BRL'
      });
      gtag('event', 'conversion', {
        'send_to': 'AW-10853702692/IMcMCOqxrtgDEKS4ubco',
        'value': getCookie('gpx_value'),
        'currency': 'BRL'
      });
      setCookie('gpx', undefined)
      setCookie('gpx_value', undefined)
      break;
  }
});

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}