$(document).ready(function () {
  if ($('.register-container').length === 0){
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);
  let utmSource = urlParams.get('utm_source');
  let utmCampaign = urlParams.get('utm_campaign');
  let plan_id = urlParams.get('plan_id');

  if (utmSource) {
    setCookie('utm_source', utmSource, 3);
  }
  if (utmCampaign) {
    setCookie('utm_campaign', utmCampaign, 3);
  }
  if (plan_id) {
    setCookie('plan_id', plan_id, 1);
  }

});

function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
